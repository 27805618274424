* {
  font-family: 'Press Start 2P', cursive;
  color: white;
}
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
body {
  // display: flex !important;
  // justify-content: center;
  // align-items: center;
  background-color: grey;
  margin:0;
  padding:0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
}
.btns-container {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

}
h2 {
  font-size: 14px;
}
.grid {
  display: grid;
  grid-template-columns: 25vw 50vw 25vw ; 
  overflow-x: hidden;
  position: relative;
}


.right-column-container, .top-score-container {
  display: flex;
  justify-content: center;
}

.right-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & .info {
    display: flex;
    flex-flow: column nowrap;
  }
}

.top-score {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
}
.score-container {
  text-transform: uppercase;
  li {
    margin-bottom: 5px;
  }
  .active-player {
    color: yellow;
    text-shadow: 1px 1px 0 tomato;
  }
}
.board-container {
  display: flex;
  align-items: top;
  justify-content: center;
}
.game-board {
  border: solid 2px;
  display: inline-flex;
}

.play-button {
  background-color: #4caf50;
  font-size: 16px;
  padding: 15px 30px;
  cursor: pointer;
  margin-top: 20px;
  max-width: 200px;
}

.direction-controll {
  display: flex;
  justify-content: space-between;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 250px;
  & .upper {
    display: flex;
    justify-content: center;
  }
  & .lower {
    display: flex;
    justify-content: center; 
  }
  & .btnControll{
    color: grey;
    display: flex;
    height: 44px;
    flex-basis: 30%;
    align-items: center;
    text-align: center;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  // & .btnControll:first-child {
  //   display: block;
  //   margin-left: 32%;
  //   margin-right: 32%;
  //   margin-bottom: 5px;
  
  // }
  
}
.btn-round {
  width: 25px;
  height: 25px;
  background: none;
  border: none;
  padding: 0;
  svg {
    path {
      fill: white;
    }
  }
}


@include bp(milli) {
  .grid {
    grid-template-columns: 15vw 70vw 15vw; 
    justify-content: center;
  }
  .top-score {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: -100vw;
  }
  .right-column {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: -100vw;
    background-color: grey;
  }
  .info {
    display: none;
  }
  .music {
    position: relative;
    top: 5px;
    right: 5px;
  }
}
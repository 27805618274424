$browser-context: 16;
$xx-large: 2560px;
$x-large : 1440px;
$large: 1024px;
$medium: 768px;
$small: 414px;
$x-small: 320px;

$global-width: $x-large;
@function em($pixels, $context: $browser-context) {
  @return ($pixels / $context) * 1em;
}

@mixin bp($point) {
  @if $point == desktop {
    @media (max-width: $xx-large) { @content ; }
  }
  @if $point == laptop {
    @media (max-width: $x-large) { @content ; }
  }  
  @if $point == small-laptop {
    @media (max-width: $large) { @content ; }
  }
  @if $point == tablet {
    @media (max-width: $medium) { @content ; }
  }
  @if $point == milli {
    @media (max-width: 600px)  { @content ; }
  }
  @if $point == phablet {
    @media (max-width: $small)  { @content ; }
  }
  @if $point == mobile {
    @media (max-width: $x-small)  { @content ; }
  }
}
* {
  font-family: "Press Start 2P", cursive;
  color: white;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  background-color: grey;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
}

.btns-container {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

h2 {
  font-size: 14px;
}

.grid {
  display: grid;
  grid-template-columns: 25vw 50vw 25vw;
  overflow-x: hidden;
  position: relative;
}

.right-column-container, .top-score-container {
  display: flex;
  justify-content: center;
}

.right-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.right-column .info {
  display: flex;
  flex-flow: column nowrap;
}

.top-score {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.score-container {
  text-transform: uppercase;
}
.score-container li {
  margin-bottom: 5px;
}
.score-container .active-player {
  color: yellow;
  text-shadow: 1px 1px 0 tomato;
}

.board-container {
  display: flex;
  align-items: top;
  justify-content: center;
}

.game-board {
  border: solid 2px;
  display: inline-flex;
}

.play-button, .modal-contianer .modal-inner button {
  background-color: #4caf50;
  font-size: 16px;
  padding: 15px 30px;
  cursor: pointer;
  margin-top: 20px;
  max-width: 200px;
}

.direction-controll {
  display: flex;
  justify-content: space-between;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 250px;
}
.direction-controll .upper {
  display: flex;
  justify-content: center;
}
.direction-controll .lower {
  display: flex;
  justify-content: center;
}
.direction-controll .btnControll {
  color: grey;
  display: flex;
  height: 44px;
  flex-basis: 30%;
  align-items: center;
  text-align: center;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-round {
  width: 25px;
  height: 25px;
  background: none;
  border: none;
  padding: 0;
}
.btn-round svg path {
  fill: white;
}

@media (max-width: 600px) {
  .grid {
    grid-template-columns: 15vw 70vw 15vw;
    justify-content: center;
  }

  .top-score {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: -100vw;
  }

  .right-column {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: -100vw;
    background-color: grey;
  }

  .info {
    display: none;
  }

  .music {
    position: relative;
    top: 5px;
    right: 5px;
  }
}
.modal-contianer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  transform: scale(0) translate(-50%, -50%);
  z-index: 100;
  overflow: hidden;
  transition: all 400ms ease;
}
.modal-contianer .modal {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-self: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 545px;
  height: 300px;
  background-color: darkorange;
}
.modal-contianer .modal-inner {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-self: center;
  width: 90%;
  height: 90%;
  background-color: grey;
  padding: 15px;
  box-sizing: border-box;
}
.modal-contianer .modal-inner .btn-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.modal-contianer .modal-inner input {
  margin-top: 80px;
  height: 48px;
  color: black;
  text-indent: 20px;
  text-transform: uppercase;
}
.modal-contianer .modal-inner button {
  margin-top: 20px;
  max-width: 200px;
  min-height: 68px;
  text-transform: uppercase;
}
.modal-contianer .modal-inner .keepbtn {
  display: none;
  margin-right: 10px;
  text-transform: uppercase;
}

.active {
  transform: scale(1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 800ms ease;
}

@media (max-width: 600px) {
  .modal-contianer .modal {
    width: 100%;
    height: 100%;
  }
  .modal-contianer .btn-container {
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
}
/*# sourceMappingURL=index.021e87e3.css.map */

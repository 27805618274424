.modal-contianer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: rgba(#000000, .4);
  transform: scale(0) translate(-50%, -50%);
  z-index: 100;
  overflow: hidden;
  transition: all 400ms ease;
  & .modal {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-self: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform:  translate(-50%, -50%);
    width: 545px;
    height: 300px;
    background-color: darkorange;
  }
  & .modal-inner {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-self: center;
    width: 90%;
    height: 90%;
    background-color: grey;
    padding: 15px;
    box-sizing: border-box;
    .btn-container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
    }
    input {
      margin-top: 80px;
      height: 48px;
      color: black;
      text-indent: 20px;
      text-transform: uppercase;
    }
    button {
      @extend .play-button;
      margin-top: 20px;
      max-width: 200px;
      min-height: 68px;
      text-transform: uppercase;
    }
    .keepbtn {
      display: none;
      margin-right: 10px;
      text-transform: uppercase;
    }
   
  }
}

.active {
  transform: scale(1);
  top: 50%;
  left: 50%;
  transform:  translate(-50%, -50%);
  transition: all 800ms ease;
}

@include bp(milli) {
  .modal-contianer {
    & .modal{
      width: 100%;
      height: 100%;
    }
    & .btn-container {
      flex-flow: column;
      align-items: center;
      justify-content: center;
    }
  }
}